import React, { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TextField } from '@mui/material';
import axios from "axios";
import dayjs from "dayjs";

export default function Appointment() {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [selectedOption, setSelectedOption] = useState("");
    const [selectDate, setSelectDate] = useState(null);
    const [selectTime, setSelectTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [appointments, setAppointments] = useState([]);

    const showNotification = (title, body) => {
        if (Notification.permission === "granted") {
            new Notification(title, { body });
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    new Notification(title, { body });
                }
            });
        }
    };

    useEffect(() => {
        setLoading(true);
        axios.get("https://appointment-backend-1.onrender.com/api/appointments")
            .then(response => {
                setAppointments(response.data);
                console.log("Fetched appointments:", response.data);
                showNotification(
                    "Appointment Accepted",
                    "Your appointment has been accepted."
                );
            })
            .catch(error => console.error("Error fetching appointments:", error))
            .finally(() => setLoading(false));
    }, []);



    const handleChangeName = (event) => setName(event.target.value);
    const handleChangeNumber = (event) => setNumber(event.target.value);
    const handleChangeDate = (newDate) => setSelectDate(newDate);
    const handleChangeTime = (newTime) => setSelectTime(newTime);
    const handleChange = (event) => setSelectedOption(event.target.value);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectDate || !selectTime) {
            alert("Please select Date and time");
            console.error("Date and time must be selected");
            return;
        }

        const formattedDateTime = dayjs(`${selectDate.format('YYYY-MM-DD')} ${selectTime.format('HH:mm')}`).toISOString();

        const appointmentData = {
            name,
            number: number ? Number(number) : null,
            selectedOption,
            datetime: formattedDateTime,
        };

        console.log("Selected Option:", selectedOption);
        console.log("Submitting appointment data:", appointmentData);

        setLoading(true);
        axios.post("https://appointment-backend-1.onrender.com/api/appointments", appointmentData)
            .then(response => {
                setMessage("Your appointment has been booked successfully!");
                setAppointments([...appointments, response.data]);
                setName('');
                setNumber('');
                setSelectedOption('');
                setSelectDate(null);
                setSelectTime(null);

                setTimeout(() => setMessage(''), 5000);
            })
            .catch(error => {
                console.error("Error saving appointment:", error);
                setMessage("Failed to save appointment. Please try again.");
                setTimeout(() => setMessage(''), 5000);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="Appointment">
            <div className="background-svg"></div>
            <form onSubmit={handleSubmit}>
                <label className="inputfiled">
                    Name:
                    <input
                        type="text"
                        value={name}
                        onChange={handleChangeName}
                        placeholder="Enter your name"
                        required
                    />
                </label>
                <label className="inputfiled">
                    Number:
                    <input
                        type="number"
                        value={number}
                        onChange={handleChangeNumber}
                        placeholder="Enter your Number"
                        required
                    />
                </label>

                <select
                    className="form-select changeBorder"
                    value={selectedOption}
                    onChange={handleChange}
                    required
                >
                    <option value="">Please select services </option>
                    <option value="Eyebrow (Threading)">Eyebrow (Threading)</option>
                    <option value="Wax">Wax</option>
                    <option value="Haircuts">Haircuts</option>
                    <option value="Hair colours">Hair colours</option>
                    <option value="Facial">Facial</option>
                    <option value="Bleach">Bleach</option>
                    <option value="D-tan">D-tan</option>
                    <option value="Meni-pedicure">Meni-pedicure</option>
                    <option value="Permanent hair spa">Permanent hair spa</option>
                    <option value="Highlights">Highlights</option>
                    <option value="Hair spa">Hair spa</option>
                    <option value="Smoothning">Smoothning</option>
                    <option value="Keratin">Keratin</option>
                    <option value="Kerabond">Kerabond</option>
                    <option value="Mirror shine">Mirror shine</option>
                    <option value="Nail arts">Nail arts</option>
                    <option value="Sider order">Sider order</option>
                    <option value="Full bridal order">Full bridal order</option>
                </select>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'TimePicker']}>
                        <DatePicker
                            label="Select Date"
                            value={selectDate}
                            onChange={handleChangeDate}
                            renderInput={(params) => <TextField {...params} />}
                            required
                        />

                        <TimePicker
                            label="Select Time"
                            value={selectTime}
                            onChange={handleChangeTime}
                            ampm={true}
                            required
                        />
                    </DemoContainer>
                </LocalizationProvider>


                <div className="btn-Class">
                    <button className="submit-btn" type="submit" disabled={loading}>Submit</button>
                </div>
            </form>

            {/* {loading && <p>Loading...</p>} */}
            {message && <p>{message}</p>}
            {/* Uncomment if you want to display the list of appointments */}
            {/* <ul>
                {appointments.map((appointment) => (
                    <li key={appointment._id}>
                        {appointment.name} - {appointment.datetime}
                    </li>
                ))}
            </ul> */}
        </div>
    );
}
// change
