import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Appoinment from "./Appoinment";
// import ShowAppointment from "./ShowAppointment";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Appoinment></Appoinment>} />
          {/* <Route
            exact
            path="/show"
            element={<ShowAppointment></ShowAppointment>}
          /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
